<template>
  <van-form
    :class="{
      'van-form__text-color': !isAdd
    }"
    :show-error-message="false"
    label-width="94px"
    @submit="onSubmit"
  >
    <van-field
      v-if="isAdd"
      :required="isAdd"
      :rules="[{ required: true }]"
      label="验收意见"
      name="passStatus"
    >
      <template #input>
        <van-radio-group
          v-model="checkInfo.passStatus"
          :disabled="!isAdd || editable"
          direction="horizontal"
        >
          <van-radio
            v-for="item in checkedIdeaTypes"
            :key="item.value"
            :name="item.value"
            >{{ item.label }}</van-radio
          >
        </van-radio-group>
      </template>
    </van-field>
    <template v-if="+checkInfo.passStatus === 1">
      <van-field
        v-if="!isAdd"
        :disabled="!isAdd || editable"
        :required="isAdd"
        :rules="[{ required: true, message: '请选择点击选择时间' }]"
        :value="checkInfo.checkDate"
        :clickable="isAdd"
        label="验收日期"
        name="checkDate"
        :placeholder="placeholder('time')"
        readonly
        @click="onTimeClick"
      />
      <van-field
        key="checkRemark"
        v-model="checkInfo.checkRemark"
        :required="isAdd"
        :readonly="!isAdd"
        :disabled="editable"
        :rules="[{ required: isAdd, message: '请输入整改效果评价' }]"
        autosize
        :label="isZM ? '整改效果评价' : '验收情况'"
        maxlength="500"
        name="checkRemark"
        :placeholder="placeholder()"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('checkRemark')"
      />
      <van-field v-if="isAdd" key="现场照片" label="现场照片" name="uploader">
        <template #input>
          <image-uploader
            key="image-uploader-2"
            ref="image-uploader-2"
            v-model="fileList"
            :max-count="5"
            :deletable="isAdd"
            :disabled="!isAdd"
            @change="onImageChange"
          />
        </template>
      </van-field>
      <van-field
        key="bonus"
        v-model="checkInfo.bonus"
        :formatter="formatter"
        format-trigger="onBlur"
        :label="isZM ? '奖惩金额' : '奖励金额'"
        :rules="[{ required: isRh }]"
        :required="isRh"
        maxlength="8"
        type="number"
        :readonly="!isAdd"
        :disabled="editable"
        :placeholder="placeholder()"
      />
      <van-field
        v-if="editable"
        :required="isAdd"
        :disabled="approveSign"
        :rules="[{ required: true }]"
        label="会签意见"
        name="countersignOpinion"
      >
        <template #input>
          <van-radio-group
            v-model="checkInfo.countersignOpinion"
            :disabled="!isAdd || approveSign"
            direction="horizontal"
          >
            <van-radio
              v-for="item in opinionOptions"
              :key="item.value"
              :name="item.value"
              >{{ item.label }}</van-radio
            >
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-if="!checkInfo.countersignOpinion && editable"
        v-model="checkInfo.countersignDesc"
        autosize
        :disabled="approveSign"
        label="不同意原因"
        :readonly="!isAdd"
        maxlength="500"
        :required="isAdd"
        :rules="[{ required: true }]"
        name="countersignDesc"
        :placeholder="placeholder()"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('countersignDesc')"
      />
    </template>
    <template v-else>
      <van-field
        key="content"
        v-model="checkInfo.content"
        :disabled="!isAdd"
        :show-word-limit="isAdd"
        autosize
        label="不合格原因"
        maxlength="500"
        :placeholder="placeholder()"
        rows="2"
        type="textarea"
        @input="handleInput('content')"
      />

      <van-field key="不合格照片" label="不合格照片" name="uploader">
        <template #input>
          <image-uploader
            key="checkInfoFiles"
            ref="image-uploader"
            v-model="badFileList"
            :max-count="5"
            :deletable="isAdd"
            :disabled="!isAdd"
            @change="onImageChange"
          />
        </template>
      </van-field>
    </template>

    <div v-if="isAdd" class="details-page__footer-button-wrapper--fixed">
      <van-button
        :disabled="loading.status || approveSign"
        :loading="loading.status"
        :loading-text="loading.text"
        block
        native-type="submit"
        round
        type="info"
        >提交</van-button
      >
    </div>
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker
        v-model="initTime"
        :min-date="minDate"
        type="date"
        @cancel="showTimePicker = false"
        @confirm="onDatetimeConfirm"
      />
    </van-popup>
  </van-form>
</template>

<script>
import { checkedIdeaTypes, opinionOptions } from "./utils/constant";
import { deepClone, filterEmoji, operateMessage, parseTime } from "@/utils";
import { loading } from "@/utils/constant";
import {
  checkTrouble,
  countersignTrouble,
  getTroubleCheckInfoById,
  getTroubleInfoById,
  refuseApproves,
  troubleApprovesTempRecord
} from "@/api/psm/trouble";
import ImageUploader from "@/views/trouble/trouble-fix/components/ImageUploader";
import { getFileUrlForFileSystem } from "@/utils/file";

export default {
  components: { ImageUploader },
  props: ["id"],
  data() {
    return {
      opinionOptions,
      editable: false,
      checkedIdeaTypes,
      showTimePicker: false,
      minDate: new Date(),
      initTime: new Date(),
      loading: { ...loading },
      troubleInfo: {},
      isAdd: false,
      approveSign: false,
      fileList: [],
      badFileList: [],
      checkInfo: {
        files: [],
        passStatus: "1",
        troubleId: "",
        content: "",
        bonus: "",
        checkDate: "",
        checkRemark: "",
        countersignOpinion: 1,
        countersignDesc: ""
      }
    };
  },
  computed: {
    isZM() {
      // 是不是中煤
      return window.globalConfig.VUE_APP_IS_ZM === "true";
    }
  },
  watch: {
    "checkInfo.passStatus": {
      async handler(val) {
        await this.$nextTick();
        if (+val === 0) {
          this.$refs["image-uploader"]?.setInnerValue(this.badFileList);
          Object.keys(this.checkInfo).forEach(key => {
            if (
              key !== "troubleId" &&
              key !== "passStatus" &&
              key !== "content" &&
              key !== "files"
            ) {
              this.checkInfo[key] = "";
            }
          });
        } else if (+val === 1) {
          this.$refs["image-uploader-2"]?.setInnerValue(this.fileList);
          this.checkInfo.content = "";
        }
      }
    }
  },
  async mounted() {
    await this.$nextTick();
    this.isAdd = JSON.parse(this.$route.query.add);
    if (!this.isAdd) {
      await this.getInfo();
    } else {
      this.troubleInfo = await getTroubleInfoById(this.id);
      const finishedFiles = this.troubleInfo.finishedFiles ?? [];
      this.badFileList = this.formatImages(finishedFiles);
      this.fileList = this.formatImages(finishedFiles);
    }
    if (+this.checkInfo.passStatus !== 1) {
      this.$refs["image-uploader"]?.setInnerValue(this.badFileList);
    } else {
      this.$refs["image-uploader-2"]?.setInnerValue(this.fileList);
    }
    this.approveSign = false;
    this.editable = false;
    this.gettroubleRecord();
  },
  methods: {
    async gettroubleRecord() {
      try {
        const res = await troubleApprovesTempRecord({
          troubleId: this.id
        });
        if (res) {
          this.editable = true;
          this.approveSign = Boolean(+res.approveSign);
          let countersignOpinion = 1;
          let countersignDesc = "";
          if (res?.operateRecordsVO) {
            countersignOpinion = res?.operateRecordsVO?.countersignOpinion;
            countersignDesc = res?.operateRecordsVO?.countersignDesc;
          }
          this.checkInfo.checkRemark = res.checkDesc;
          this.checkInfo.bonus = res.checkBonus;
          this.checkInfo.content = res.checkRefusalReason;
          this.checkInfo.countersignOpinion = countersignOpinion;
          this.checkInfo.countersignDesc = countersignDesc;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async signToggle(info) {
      try {
        const params = {
          ...info,
          troubleId: info.troubleId,
          stage: +this.troubleInfo.stage,
          operate: info.operate,
          countersignOpinion: info.countersignOpinion,
          countersignDesc: info.countersignDesc
        };
        const res = await countersignTrouble(params);
        if (res) this.$router.back();
      } catch (error) {
        console.log(error);
      }
    },
    formatImages(imgs = []) {
      if (!imgs?.length) return [];
      return imgs.map(img => {
        return deepClone({
          ...img,
          url: getFileUrlForFileSystem(img.id, false),
          name: img.name,
          fileInfo: img,
          isImage: true
        });
      });
    },
    onImageChange(val) {
      if (+this.checkInfo.passStatus === 0) {
        this.badFileList = val;
      } else {
        this.fileList = val;
      }
    },
    async getInfo() {
      try {
        this.checkInfo = await getTroubleCheckInfoById(this.id);
        this.troubleInfo = await getTroubleInfoById(this.id);
        const finishedFiles = this.troubleInfo.finishedFiles ?? [];
        this.badFileList = this.formatImages(finishedFiles);
        this.fileList = this.formatImages(finishedFiles);
      } catch (e) {
        console.log(e);
      }
    },
    placeholder(type = "content") {
      let content = "请输入内容";
      if (type === "time") {
        content = "点击选择时间";
      }
      return this.isAdd ? content : "--";
    },
    async checkTrouble() {
      try {
        const r = await checkTrouble(this.checkInfo);
        operateMessage(r, "整改验收");
        if (r) this.$router.back();
      } catch (e) {
        console.log("checkTrouble -> e", e);
      }
    },
    async refuseCheck() {
      try {
        const ret = await refuseApproves(this.checkInfo);
        operateMessage(ret, "验收拒绝");
        if (ret) this.$router.back();
      } catch (e) {
        console.log("refuse -> e", e);
      }
    },
    onSubmit() {
      this.checkInfo.troubleId = this.id;
      if (+this.checkInfo.passStatus === 0) {
        this.checkInfo.files = this.badFileList;
        this.refuseCheck();
      } else {
        this.checkInfo.files = this.fileList;
        if (this.editable) {
          const params = {
            ...this.checkInfo,
            operate: 11
          };
          this.signToggle(params);
        } else {
          this.checkTrouble();
        }
      }
    },
    handleInput(key) {
      this.checkInfo[key] = filterEmoji(this.checkInfo[key]);
    },
    formatter(value) {
      const dotIndex = value.indexOf(".");
      const sliceLen = dotIndex !== -1 ? dotIndex + 3 : value.length;
      return value.slice(0, sliceLen);
    },
    onTimeClick() {
      if (!this.isAdd) return;
      this.checkInfo.finishTime &&
        (this.initTime = new Date(this.checkInfo.finishTime));
      this.showTimePicker = true;
    },
    onDatetimeConfirm(time) {
      this.checkInfo.checkDate = parseTime(time, "{y}-{m}-{d}");
      this.showTimePicker = false;
    }
  }
};
</script>

<style></style>
